<template>
  <div class="article-section-gps row">
    <BaseRadio
        class="col-12 d-flex flex-column"
        v-model="article.setting.readersAccessibility"
        :options="optionsReadersAccessibility"
        name="article_paid_article"
        :label="$t('article.paid_article')"
        label-divider
        :disabled="articleEditDisabled"
    />

    <div class="col-12">
      <hr>
    </div>

    <Checkbox
        class="col-12"
        v-model="article.setting.showCommercials"
        id="article_enableAdvertising"
        :label="$t('article.show_advertising')"
        :disabled="articleEditDisabled"

    />

    <SwitchCheckbox
        v-model="layout"
        id="article_layout"
        class="col-12"
        size="md"
        :disabled="articleEditDisabled"
        :custom-class="'mt-0'"
    >
      <template v-slot:trueLabel>
        <div class="article-section-gps__layout-option">
            <span class="article-section-gps__layout-option__item">
              <span class="article-section-gps__layout-option__item__background"></span>
            </span>

          {{ $t('article.article_layout.primary') }}
        </div>
      </template>

      <template v-slot:falseLabel>
        <div class="article-section-gps__layout-option">
          <span class="article-section-gps__layout-option__item"></span>
          {{ $t('article.article_layout.fullwidth') }}
        </div>
      </template>
    </SwitchCheckbox>

    <SwitchCheckboxAside
        class="col-12"
        v-model="enableLocationSelect"
        id="article_location_enabled"
        size="md"
        :label="$t('article.geolocation')"
        :disabled="articleEditDisabled"
        divider
    />

    <div v-if="enableLocationSelect" class="col-12 d-flex flex-column">
      <span v-if="article.location && article.location.address" class="article-section-gps__location-text mb-1">
        {{ article.location.address }}
      </span>

      <button class="btn btn-info flex-fill" :disabled="articleEditDisabled" @click="handleLocationModal">
        {{ $t('article.geolocation_insert') }}
      </button>

      <ArticleLocationModal
          v-if="showLocationModal"
          :location="article.location"
          @submit="submitAddress"
          @close="closeLocationModal"
      />
    </div>

    <div class="col-12">
      <OutlinedCheckbox
          v-model="article.oneSignalNotified"
          id="publish_notification"
          :label="$t('article.notificationService.oneSignalNotified')"
          :disabled="articleEditDisabled"
          class="mt-0"
      />
    </div>

    <div v-if="article.oneSignalNotified" class="col-12">
      <OutlinedCheckbox
          v-model="article.notificationPostponed"
          id="publish_delayed_notification"
          :label="$t('article.notificationService.notificationPostponed')"
          :disabled="articleEditDisabled"
          class="mt-0"
      />
    </div>

    <div class="col-12">
      <hr>
    </div>

    <Checkbox
        class="col-12"
        v-model="article.setting.showDiscussion"
        id="article_showDiscussion"
        :label="$t('article.enable_discussions')"
        :disabled="articleEditDisabled"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Checkbox from '@/components/form/Checkbox.vue'
import Radio from '@/components/form/Radio.vue'
import SwitchCheckbox from '@/components/form/SwitchCheckbox.vue'
import SwitchCheckboxAside from '@/components/form/SwitchCheckboxAside.vue'
import ArticleLocationModal from '@/components/article/ArticleLocationModal.vue'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox.vue'
import {
  ARTICLE_LAYOUT_FULLWIDTH,
  ARTICLE_LAYOUT_PRIMARY,
  ARTICLE_READER_ACCESSIBILITY,
  ARTICLE_READER_ACCESSIBILITY_SUBSCRIBERS
} from '@/model/ArticleModel'
import { cloneDeep } from 'lodash'
import LocationModel from '@/model/LocationModel'
import { SITE_ID_DENIK_CZ } from '@/model/SiteModel'

export default {
  name: 'ArticleGPS',
  components: {
    OutlinedCheckbox,
    ArticleLocationModal,
    SwitchCheckboxAside,
    SwitchCheckbox,
    BaseRadio: Radio,
    Checkbox
  },
  data () {
    return {
      enableLocationSelect: false,
      showLocationModal: false
    }
  },
  props: {
    article: {
      type: Object,
      required: true
    },
    dataLoaded: {
      type: Boolean,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    articleEditDisabled () {
      return this.$store.getters['article/articleEditDisabled']
    },
    optionsReadersAccessibility () {
      return ARTICLE_READER_ACCESSIBILITY.map(item => {
        return {
          id: item,
          value: item,
          title: this.$t(`article.paid_article_options.${item}`),
          disabled: ARTICLE_READER_ACCESSIBILITY_SUBSCRIBERS === item && this.article.site !== SITE_ID_DENIK_CZ
        }
      })
    },
    layout: {
      get () {
        return this.article.setting.layout === ARTICLE_LAYOUT_PRIMARY
      },
      set (value) {
        this.article.setting.layout = value ? ARTICLE_LAYOUT_PRIMARY : ARTICLE_LAYOUT_FULLWIDTH
        return value
      }
    }
  },
  watch: {
    'article.oneSignalNotified' (newVal) {
      if (newVal) {
        this.article.notificationPostponed = false
      }
    },
    'article.location' (newVal) {
      if (newVal.address !== '') {
        this.enableLocationSelect = true
      }
    },
    enableLocationSelect (newVal) {
      if (!newVal) {
        this.article.location = cloneDeep(LocationModel)
      }
    }
  },
  methods: {
    handleLocationModal () {
      this.showLocationModal = true
    },
    closeLocationModal () {
      this.showLocationModal = false
    },
    submitAddress (value) {
      this.article.location = value
    }
  }
}
</script>

<style lang="scss" scoped>
.article-section-gps {
  gap: rem(16px);

  &__layout-option {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    gap: 8px;

    &:last-of-type {
      justify-content: flex-end;
    }

    &__item {
      border: 1px solid #d1dbe4;
      width: rem(32px);
      height: rem(16px);
      position: relative;

      &__background {
        position: absolute;
        width: 35%;
        height: 100%;
        right: 0;
        background-color: #d1dbe4;
      }
    }
  }

  &__location-text {
    @include font(400 14px "Roboto");
    color: #8a96ac;
  }

  hr {
    margin: 0;
    border-top-width: 2px;
  }
}
</style>
